
@layer library, reset, base, demo;
@import 'https://unpkg.com/open-props@2.0.0-beta.5' layer(library);
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300..700&display=swap');
@import "variables";

:root {
  --blue: #01266c;
  --gold: #f7c505;
  --yellow: #fbdc74;
  --skyblue: #0071fb;
  --black: #1a1a1a;
  --color: rgba(122, 122, 124, 0.918);
  --offset: 6px;
  --primary: #212121;
  --secondary: #515151;
  --white: #ffffff;
}
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
  border-radius: 10px;
}
::-webkit-scrollbar {
  width: 6px;
}
::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: var(--secondary);
}
::-webkit-scrollbar-thumb:hover {
  background: var(--secondary);
}
::selection {
  background: var(--primary);
  color: var(--white);
}


body,
html {
  //scroll-behavior: smooth;
  //overflow-x: hidden;
}

body {
  margin: 0;
  font-family: 'Poppins', sans-serif;
  font-size: 1rem;
  height: 100%;
  overflow-x: hidden;
}
//.smothScroll{
//
//}

* {
  margin: 0;
  padding: 0;
  outline: none;
  text-decoration: none;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
}

a{
  color: #000;
  text-decoration: none;
  transition: 0.2s;
  &:hover{
    color: #000;
    transition: 0.2s;
    text-decoration: none;
  }
}

p, h1, h2, h3, h4, h5{
  margin:0;
}

ul, li{
  padding: 0;
  margin: 0;
  list-style: none;
}

.cursor {
  border-radius: 50%;
  width: 32px;
  height: 32px;
  background-color: white;
  mix-blend-mode: difference;
  position: fixed;
  top: 0;
  left: 0;
  will-change: transform;
  pointer-events: none;
}

.title-wrapper {
  margin-bottom: 2rem;
  h2{
    display: inline;
    text-transform: uppercase;
    font-size: 42px;
    font-weight: bold !important;
    &.text-black{
      color: $black;
      border-bottom: 1px solid $black;
    }
    &.text-white{
      color: $white;
      border-bottom: 1px solid $white;
    }
  }
}

//.title-wrapper {
//  position: absolute;
//  left: 0;
//  height: 100%;
//  width: 100px;
//  background-color: #cccccc57;
//  top: 0;
//  h2{
//    color: #000;
//    white-space: nowrap;
//    text-transform: uppercase;
//    cursor: vertical-text;
//    direction: rtl;
//    font-size: 5vw;
//    display: block;
//    transform: rotate(-90deg);
//    position: fixed;
//    left: -12vw;
//    top: 50%;
//  }
//}

.btn-custom {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  letter-spacing: 0.1px;
  line-height: 23px;
  border: none;
  overflow: hidden;
  position: relative;
  transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out;
  z-index: 1;
  font-size: 19px;
  height: 57px;
  padding: 0 28px;
  cursor: pointer;
  span{
    z-index: 2;
    position: relative;
  }
  &:hover, &:active,  &:focus{
    background-color: $black;
    color: $white;
  }
  &.btn-white{
    background-color: $white;
    color: $black;
  }
  &.btn-black{
    background-color: $black;
    color: $white;
  }
  &.slide-white{
    &:before {
      content: "";
      position: absolute;
      background: $white;
      bottom: 0;
      left: 0;
      right: 0;
      top: 100%;
      z-index: -1;
      transition: top 0.18s ease-out;
      width: 100%;
    }
    &:hover, &:active,  &:focus{
      &:before {
        top: 0;
        background-color: $white;
      }
      color: $dark-grey !important;
    }
  }
  &.slide-black{
    &:before {
      content: "";
      position: absolute;
      background: $black;
      bottom: 0;
      left: 0;
      right: 0;
      top: 100%;
      z-index: -1;
      transition: top 0.18s ease-out;
      width: 100%;
    }
    &:hover, &:active,  &:focus{
      &:before {
        top: 0;
        background-color: $black;
        z-index: 1;
      }
      color: $white !important;
    }
  }
}

.cust-input {
  width: 100%;
  border: 1px solid rgb(0 0 0 / 10%);
  height: 60px;
  padding: 16px 22px;
  color: $black-80;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 29px;
  box-shadow: none;
  outline: none;
  border-radius: 8px;
}

.intro-wrapper{
  .intro{
    --target: 100%;
    width: 100%;
    background: linear-gradient(to right, #000 var(--target), #fff var(--target));
    display: flex;
    height: 100vh;
    justify-content: center;
    align-items: center;
    h1 {
      color: white;
      font-size: clamp(2.5rem, 8vw, 6rem);
      text-align: center;
      letter-spacing: -0.02em;
      background: linear-gradient(to right, #fff var(--target), #000 var(--target));
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      width: 100%;
      display: block;
      line-height: 1;
      span {
        font-size: .37em;
        font-weight: 300;
        line-height: 1.3;
        display: block;
      }
    }
    .container_mouse {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 40px;
      .mouse-btn {
        width: 40px;
        height: 80px;
        border: 2px solid rgba(122, 122, 124, 0.918);
       margin: 0 auto;
        border-radius: 30px;
        display: flex;
        .mouse-scroll {
          display: block;
          width: 20px;
          height: 20px;
          background: linear-gradient(170deg, rgba(122, 122, 124, 0.918), rgb(123, 124, 124));
          border-radius: 50%;
          margin: auto;
          animation: scrolling 1s linear infinite;
        }
      }
      @keyframes scrolling {
        0% {
          opacity: 0;
          transform: translateY(-20px);
        }

        100% {
          opacity: 1;
          transform: translateY(20px);
        }
      }
    }
  }
  .intro-path{
    .intro-svg{
      overflow:visible;
      enable-background:new 0 0 58.61 238.39;
      max-width: 300px;
      margin: 50px auto 200px;
      display: block;
      .st0{
        fill:#FFFFFF;
        stroke:#000000;
        stroke-miterlimit:10;
      }
    }
  }
}

.main-wrapper{
  .stickyNavbar {
    //background: #0c0c0c;
    //position: fixed;
    //top: 0;
    position: absolute;
    top: 16px;
    height: 60px;
    transition: top 0.2s ease-in-out;
    width: 100%;
    z-index: 2;
    ul {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 32px;
      //height: 60px;
      li{
        a{
          color: #fff;
          display: flex;
          align-items: center;
          justify-content: center;
          &:hover{
            color: rgba(255, 255, 255, 0.8);
          }
         img {
            width: 200px;
            transform: none !important;
          }
        }
      }
    }
    &.nav-up {
      top: -60px;
      transition: top 0.2s ease-in-out;
    }
  }
  .intro{
    position: relative;
    width: 100%;
    z-index: 1;
    .future-content {
      overflow: hidden;
      .welcome-wrapper {
        //background-image: url(https://images.unsplash.com/photo-1589848315097-ba7b903cc1cc?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D);
        background-image: url(../images/future-bg.jpg);
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        width: 100%;
        height: 100vh;
        &:before{
          content: "";
          position: absolute;
          left: 0;
          right:0;
          top: 0;
          width: 100%;
          height: 100%;
          backdrop-filter: blur(10);
          background-color: rgba(0, 0, 0, 0.6);
        }
        h2 {
          position: relative;
          z-index: 2;
          width: 960px;
          font-size: 42px;
          line-height: 1.2;
          letter-spacing: -0.02em;
          color: #fff;
          text-align: center;
          font-weight: normal;
          opacity: 0;
          @media screen and (max-width: 991px) {
            font-size: 32px;
            margin-top: 0 !important;
            width: 500px;
          }
          @media screen and (max-width: 767px) {
            font-size: 22px;
            line-height: 1.2;
            width: 320px;
          }
          span{
            font-weight: bold;
            font-style: normal;
          }
          .mask {
            height: 70px;
            position: relative;
            overflow: hidden;
            margin-top: var(--offset);
            width: 100%;
            font-size: 70px;
            @media screen and (max-width: 991px) {
              height: 50px;
              font-size: 32px;
              margin-top: 0 !important;
            }
            @media screen and (max-width: 767px) {
              height: 32px;
              font-size: 24px;
              margin-top: 0px !important;
            }
            span {
              display: block;
              box-sizing: border-box;
              position: absolute;
              top: 70px;
              padding-bottom: var(--offset);
              width: 100%;
              background-size: 100% 100%;
              -webkit-background-clip: text;
              background-clip: text;
              -webkit-text-fill-color: transparent;
              background-repeat: no-repeat;
              @media screen and (max-width: 991px) {
                padding-bottom: 30px;
              }
              @media screen and (max-width: 767px) {
                padding-bottom: var(--offset);
              }
              &:nth-child(1) {
                background-image: linear-gradient(45deg, #0ecffe 50%, #07a6f1);
              }
              &:nth-child(2) {
                background-image: linear-gradient(45deg, #18e198 50%, #0ec15d);
              }
              &:nth-child(3) {
                background-image: linear-gradient(45deg, #8a7cfb 50%, #633e9c);
              }
              &:nth-child(4) {
                background-image: linear-gradient(45deg, #fa7671 50%, #f45f7f);
              }
            }
            span[data-show] {
              transform: translateY(-100%);
              transition: 1s transform ease-in-out;
            }
            span[data-up] {
              transform: translateY(-200%);
              transition: 1s transform ease-in-out;
            }
          }
        }
      }
    }
    .future-image-wrapper {
      width: 100%;
      height: 100vh;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      z-index: 2;
      perspective: 500px;
      overflow: hidden;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center center;
        display: none;
        &.large{
          @media (min-width: $large-laptop) {
            display: block;
          }
        }
        &.medium{
          @media (min-width: $medium-laptop) {
            display: block;
          }
        }
        &.small{
          @media (min-width: $large-tablet) {
            display: block;
          }
        }
        &.xs{
          @media (min-width: $large-phone) {
            display: block;
          }
        }
        &.xxs{
          @media (min-width: $smallest-phone) {
            display: block;
          }
        }
      }
    }
  }
}

@layer demo {
  .whatWeDo {
    --section-block-size: max(400px, 100dvb);
    display: grid;
    background-color: white;
    min-block-size: var(--section-block-size);
    position: relative;
    block-size: 100%;
    display: grid;
    >* {
      grid-area: 1/1;
    }
    .video-visual {
      inline-size: 100%;
      block-size: var(--section-block-size);
      display: block;
      position: sticky;
      inset-block-start: 0;
      isolation: isolate;
      filter: hue-rotate(210deg);
      .video {
        display: block;
        inline-size: 100%;
        block-size: 100%;
        object-fit: cover;
        position: relative;
        z-index: -1;
      }
    }
    .whatWeDo-content {
      position: relative;
      display: grid;
      padding: 50px 30px !important;
      @media (width >=960px) {
        grid-template-columns: 1fr 1fr;
      }
      @media (width < 960px) {
        padding-block-start: calc(var(--nav-block-size) + var(--size-7));
        padding-block-end: var(--size-7);
        margin-top: 150px !important;
      }
      .content-wrapper {
        display: grid;
        @media (width < 960px) {
          gap: var(--size-7);
        }
        .content {
          display: grid;
          inline-size: 100%;
          place-items: center;
          padding-block: var(--size-7);
          padding-inline: var(--size-5);
          @media (width >=960px) {
            padding: var(--size-10);
            min-block-size: 100cqb;
            place-items: center center;
          }
          @media (width < 960px) {
            gap: var(--size-5);
          }
          .mobile-visual {
            inline-size: 100%;
            aspect-ratio: var(--ratio-square);
            @media (width >=960px) {
              display: none;
            }
            @media (max-width: $large-tablet) {
              width: 250px;
            }
            .card-img {
              display: block;
              inline-size: 100%;
              block-size: 100%;
              object-fit: contain;
              @media (max-width: $large-tablet) {
                transform: none !important;
              }
            }
          }
          .meta {
            display: grid;
            gap: var(--size-3);
            @media (max-width: $large-tablet) {
              text-align: center !important;
              .title-wrapper {
                position: absolute;
                top: -60px;
                left: 0;
                right: 0;
                margin: 0 auto;
              }
            }
            .headline {
              font-size: var(--font-size-7);
              font-weight: var(--font-weight-4);
              max-inline-size: var(--size-content-1);
              text-wrap: pretty;
              @media (width < 960px) {
                font-size: var(--font-size-6);
              }
              .text-highlight {
                background: var(--_text-gradient);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                background-clip: text;
                text-fill-color: transparent;
              }
            }
            .desc {
              font-size: var(--font-size-4);
              line-height: 1.5;
              max-inline-size: 40ch;
              text-wrap: pretty;

              @media (width < 960px) {
                font-size: var(--font-size-3);
              }
            }
          }
          &.content-1 {
            --_text-gradient: linear-gradient(227deg,
                    #1400c7 0%,
                    #00bbff 100%);
          }
          &.content-2 {
            --_text-gradient: linear-gradient(227deg,
                    #28dc28 0%,
                    #00bbff 100%);
          }
          &.content-3 {
            --_text-gradient: linear-gradient(227deg,
                    #1400c7 0%,
                    #b800b1 100%);
          }
          &.content-4 {
            --_text-gradient: linear-gradient(227deg,
                    #b800b1 0%,
                    #f50000 100%);
          }
        }
      }
      .visual {
        display: grid;
        position: sticky;
        block-size: var(--section-block-size);
        inset-block-start: 0;
        container-type: size;
        @media (width < 960px) {
          display: none;
        }
        .card-wrapper {
          container-type: size;
          display: grid;
          place-items: center;
          overflow: clip;
          >* {
            grid-area: 1/1;
          }
          .card {
            aspect-ratio: var(--ratio-square);
            inline-size: 35cqi;
            border-radius: var(--radius-3);
            scale: 0.4;
            .card-img {
              display: block;
              inline-size: 100%;
              block-size: 100%;
              object-fit: contain;
            }

            &.card-1 {
              scale: 1;
            }

            &.card-2 {
              translate: -35cqi 30cqb;
              opacity: 0.3;
            }

            &.card-3 {
              translate: 0cqi 50cqb;
              opacity: 0.5;
            }

            &.card-4 {
              translate: 45cqi 40cqb;
              opacity: 0.5;
            }
          }
        }
      }
    }
  }
}

@supports (animation-timeline: scroll()) {
  body {
    timeline-scope: --content-1, --content-2, --content-3, --content-4;
  }

  .whatWeDo {
    view-timeline-name: --section;
  }

  .content-1 {
    view-timeline-name: --content-1;
  }

  .content-2 {
    view-timeline-name: --content-2;
  }

  .content-3 {
    view-timeline-name: --content-3;
  }

  .content-4 {
    view-timeline-name: --content-4;
  }

  .card {
    animation-timing-function: linear;
    animation-fill-mode: forwards;
  }

  .card-1 {
    animation-timeline: --content-1;
    animation-name: slide-up-first-card;
  }

  .card-2 {
    animation-timeline: --content-2;
    animation-name: slide-up-card;
  }

  .card-3 {
    animation-timeline: --content-3;
    animation-name: slide-up-card;
  }

  .card-4 {
    animation-timeline: --content-4;
    animation-name: slide-up-card;
  }

  .video-visual {
    animation-timeline: --section;
    animation-range-end: exit 110%;
    animation-name: update-hue;
    animation-timing-function: step-end;
    animation-fill-mode: forwards;
  }

  @keyframes update-hue {
    0% {
      filter: hue-rotate(210deg);
    }

    25% {
      filter: hue-rotate(150deg);
    }

    45% {
      filter: hue-rotate(300deg);
    }

    60% {
      filter: hue-rotate(4deg);
    }
  }

  @keyframes slide-up-first-card {
    50% {
      translate: 0;
      opacity: 1;
    }

    90% {
      translate: 0 -50cqi;
      scale: 0.6;
    }

    100% {
      translate: 0 -100cqi;
      opacity: 0;
    }
  }

  @keyframes slide-up-card {
    50% {
      opacity: 1;
      translate: 0;
      scale: 1;
    }

    90% {
      opacity: 0.5;
      scale: 0.6;
      translate: 0 -50cqb;
    }

    100% {
      translate: 0 -100cqi;
      opacity: 0;
    }
  }
}

.aboutUs {
  height: 100vh;
  width: 100%;
  background-image: linear-gradient(135deg, #492cdd 0, #ad38e2 100%);
  padding: 0 100px;
  @media (max-width: $small-laptop) {
    height: 100%;
    padding: 50px 32px;
  }
  .intro-section{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 32px;
    height: 100vh;
    @media (max-width: $small-laptop) {
      flex-direction: column;
      gap: 50px;
      height: 100%;
    }
    .banner-content {
      width: 50%;
      padding: 0 50px;
      @media (max-width: $small-laptop) {
        width: 100%;
        padding: 0px;
        transform: none !important;
      }
      .text-wrapper {
        h4 {
          color: #fff;
          font-size: 22px;
          font-weight: 300;
          line-height: 1.5;
          margin-bottom: 2rem;
        }
      }
      .button-wrapper{
        margin-top: 2rem;
      }
    }
    .banner-slider {
      width: 50%;
      transition: opacity 0.5s linear, 0.5s filter linear;
      @media (max-width: $small-laptop) {
        width: 100%;
        transform: none !important;
      }
      .slider-holder {
        position: relative;
        width: 100%;
        margin: 0 auto;
        .slider-banner {
          position: relative;
          width: 100%;
          height: 600px;
          overflow: hidden;
          @media (max-width: $small-laptop) {
            height: 400px;
          }
          .slider-item-banner {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            /* background-color: red; */
            opacity: 0;
            visibility: hidden;
            .title{
              width: 100%;
              height: 100%;
              background-size: cover;
              &:after {
                content: '';
                position: absolute;
                width: 100%;
                height: 100%;
                left: 0;
                top: 0;
                background: rgba(0, 0, 0, 0.4);
              }
            }
            .caption {
              width: 150px;
              width: 100%;
              position: absolute;
              left: 50%;
              top: 50%;
              z-index: 9;
              transform: translate(-50%, -50%);
              p {
                font-size: 52px;
                font-weight: bold;
                text-align: center;
                color: #fff;
                @media (max-width: $small-laptop) {
                  font-size: 36px;
                }
              }
            }
          }
        }
      }
    }
  }
}

.overflow-hidden {
  overflow: hidden !important;
  position: fixed;
  width: 100%;
}

.scrollmagic-pin-spacer {
  position: absolute !important;
  clip: rect(auto, auto, auto, auto);
}

.ourServices{
  .event {
    position: relative;
    overflow: hidden;
    height: 100svh;
    width: 100vw;
    z-index: 1;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: -1;
    }
    .pinWrapper {
      height: 100%;
      width: 100%;
      position: relative;
      z-index: 11;
      .text {
        top: -50%;
        left: 0;
        position: absolute;
        transform: translate(100px, -50%);
        -webkit-transform: translate(100px, -50%);
        width: 55%;
        @media screen and (max-width: 1399px) {
          transform: translate(60px, -50%);
          -webkit-transform: translate(60px, -50%);
        }
        @media screen and (max-width: 1199px) {
          transform: translate(40px, -50%);
          -webkit-transform: translate(40px, -50%);
          width: 44%;
        }
        @media screen and (max-width: 991px) {
          transform: translate(24px, -50%);
          -webkit-transform: translate(24px, -50%);
          width: 50%;
        }
        @media screen and (max-width: 576px) {
          top: -36%;
          width: 100%;
          width: calc(100% - 80px);
        }
        h2{
          margin-bottom: 30px;
          font-size: 42px;
          line-height: 1;
          //text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
          font-weight: 400;
          color: var(--white);
          letter-spacing: 1.2px;
          text-transform: uppercase;
          @media screen and (max-width: 1399px) {
            font-size: 38px;
          }
          @media screen and (max-width: 1199px) {
            font-size: 32px;
          }
          @media screen and (max-width: 991px) {
            font-size: 28px;
          }
          @media screen and (max-width: 767px) {
            font-size: 22px;
          }
          @media screen and (max-width: 576px) {
            font-size: 18px;
          }
        }
        p{
          font-size: 44px;
          line-height: 1.4;
          font-weight: 200;
          color: var(--white);
          @media screen and (max-width: 1399px) {
            font-size: 38px;
          }
          @media screen and (max-width: 1199px) {
            font-size: 32px;
          }
          @media screen and (max-width: 991px) {
            font-size: 28px;
          }
          @media screen and (max-width: 767px) {
            font-size: 22px;
          }
          @media screen and (max-width: 576px) {
            font-size: 18px;
          }
        }
      }
      .image {
        height: 100%;
        width: 100%;
        top: 0%;
        left: 0%;
        position: absolute;
        background-size: cover;
        background-position: center center;
        transition: width 1s, height 1s;
        -webkit-transition: width 1s, height 1s;
        box-shadow: 2px 2px 10px 10px rgb(0 0 0 / 12%);
        -webkit-box-shadow: 2px 2px 10px 10px rgb(0 0 0 / 12%);
        transform-origin: center;
        -webkit-transform-origin: center;
        z-index: 8888;
        overflow: hidden;
        border-radius: 0;
        &:not(#loaderVideo) {
          top: -50%;
          width: 500px;
          height: 500px;
          left: auto;
          right: 100px;
          position: absolute;
          border-radius: 12px;
          transform: translate(0%, -50%);
          -webkit-transform: translate(0%, -50%);
          @media screen and (max-width: 1399px) {
            height: 450px;
            width: 450px;
            right: 80px;
            transform: translate(0%, -50%);
            -webkit-transform: translate(0%, -50%);
          }
          @media screen and (max-width: 1199px) {
            height: 400px;
            width: 400px;
            right: 60px;
            transform: translate(0%, -50%);
            -webkit-transform: translate(0%, -50%);
          }
          @media screen and (max-width: 991px) {
            height: 310px;
            width: 310px;
            right: 40px;
            transform: translate(0%, -50%);
            -webkit-transform: translate(0%, -50%);
          }
          @media screen and (max-width: 767px) {
            height: 220px;
            width: 220px;
          }
          @media screen and (max-width: 576px) {
            top: -75%;
            left: 24px;
            right: auto;
            transform: translate(0%, -25%);
            -webkit-transform: translate(0%, -25%);
          }
        }
      }
    }
    &#section1{
      //background-image: url("../images/leadershipTrainingBG.jpg");
      background-color: #fff;
      &:before {
        //background-color: rgba(0, 0, 0, 0.5);
      }
      .pinWrapper {
        .text {
          top: 50%;
          @media screen and (max-width: 576px) {
            top: 64%;
          }
          h2, p{
            color: #000
          }
        }
        .image{
          video {
            height: 100%;
            width: 100%;
            object-fit: cover;
            object-position: center center;
            position: absolute;
            top: 0;
            left: 0;
          }
        }
      }
    }
    &#section2{
      //background-image: url("../images/communicationTrainingBG.jpg");
      background-color: #000;
      &:before {
        //background-color: rgba(0, 0, 0, 0.5);
      }
      .pinWrapper {
        .text {
          h2, p{
            color: #fff
          }
        }
        .image {
          background-image: url("../images/communicationTraining.jpg");
        }
      }
    }
    &#section3 {
      //background-image: url("../images/managementCoachingBG.jpg");
      background-color: #fff;
      &:before {
        //background-color: rgba(0, 0, 0, 0.5);
      }

      .pinWrapper {
        .text {
          h2, p{
            color: #000
          }
        }
        .image {
          background-image: url("../images/managementCoaching.jpg");
        }
      }
    }
    &#section4{
      //background-image: url("../images/stressManagementBG.jpg");
      background-color: #000;
      &:before {
        //background-color: rgba(0, 0, 0, 0.5);
      }
      .pinWrapper {
        .text {
          h2, p{
            color: #fff
          }
        }
        .image {
          background-image: url("../images/stressManagementImage.jpg");
        }
      }
    }
  }
  @keyframes animate {
    0% {
      opacity: 0;
      transform: rotate(45deg) translate(-10px, -10px);
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0;
      transform: rotate(45deg) translate(10px, 10px);
    }
  }
  @-webkit-keyframes animate {
    0% {
      opacity: 0;
      transform: rotate(45deg) translate(-10px, -10px);
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0;
      transform: rotate(45deg) translate(10px, 10px);
    }
  }
}

.socialMedia{
  .social-media-wrapper {
    background-color: #d3ebd1;
    position: relative;
    width: 100vw;
    height: 200vh;
    .social-media-content {
      width: 100%;
      height: 100vh;
      position: sticky;
      top: 0;
      .shad {
        box-shadow: rgba(10, 49, 114, 0) 0px 0px 0px,
        rgba(10, 49, 114, 0.024) 0px 2px 0.2px,
        rgba(10, 49, 114, 0.027) 0px 4px 0.4px,
        rgba(10, 49, 114, 0.03) 0px 6.1px 0.7px,
        rgba(10, 49, 114, 0.035) 0px 8.3px 0.9px,
        rgba(10, 49, 114, 0.035) 0px 10.7px 1.2px,
        rgba(10, 49, 114, 0.04) 0px 13.5px 1.6px,
        rgba(10, 49, 114, 0.04) 0px 16.6px 2.1px,
        rgba(10, 49, 114, 0.04) 0px 20.2px 2.7px,
        rgba(10, 49, 114, 0.043) 0px 24.6px 3.5px,
        rgba(10, 49, 114, 0.043) 0px 30.1px 4.4px,
        rgba(10, 49, 114, 0.043) 0px 36.8px 5.8px,
        rgba(10, 49, 114, 0.043) 0px 45.5px 7.5px,
        rgba(10, 49, 114, 0.043) 0px 56.9px 9.9px,
        rgba(10, 49, 114, 0.047) 0px 72.2px 13.2px,
        rgba(10, 49, 114, 0.047) 0px 93.6px 17.8px,
        rgba(10, 49, 114, 0.047) 0px 125px 24.8px,
        rgba(10, 49, 114, 0.05) 0px 175.1px 36px,
        rgba(10, 49, 114, 0.055) 0px 270.8px 57.8px;
        &.emo {
          position: absolute;
          padding: 2px;
          background-color: #f0f5f0;
          font-size: 3.5em;
          border-radius: 20px;
          will-change: transform;
          cursor: default;
          user-select: none;
        }
      }
    }
  }
  .info-wrap {
    pointer-events: none;
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    .info-cont {
      pointer-events: auto;
      h1 {
        font-family: 'Dancing Script';
        font-size: 4em;
        line-height: 35px;
        color: #4287f5;
      }
      button {
        border: none;
        width: 100%;
        padding: 10px 20px;
        background-color: #4287f5;
        color: #fff;
        border-radius: 20px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
        transition: 0.2s;
        &:hover {
          background-color: #5b99fc;
        }
        .hover-btn {
          border-radius: 30%;
          height: 100%;
          width: 100%;
          position: absolute;
          left: 0;
          top: -100%;
          background-color: #fff;
          opacity: 0.1;
        }

      }
    }
  }
}

.main {
  position: relative;
  width: 100%;
  overflow: hidden;
  .gallery-wrap {
    position: relative;
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 40vh auto;
    overflow: hidden;
    .gallery {
      position: relative;
      width: 100%;
      height: 100%;
      flex: none;
      &.gallery--grid10 {
        display: grid;
        grid-template-columns: repeat(2,1fr);
        gap: 2vw;
        @media screen and (min-width: 53em) {
          grid-template-columns: repeat(10,1fr);
          grid-template-rows: repeat(4,1fr);
          &:not(.gallery--switch) {
            .pos-1 {
              grid-area: 1 / 1;
            }
            .pos-2 {
              grid-area: 1 / 2;
            }
            .pos-3 {
              grid-area: 1 / 4;
            }
            .pos-4 {
              grid-area: 1 / 5;
            }
            .pos-5 {
              grid-area: 3 / 6;
            }
            .pos-6 {
              grid-area: 1 / 7;
            }
            .pos-7 {
              grid-area: 1 / 9;
            }
            .pos-8 {
              grid-area: 3 / 10;
            }
            .pos-9 {
              grid-area: 2 / 8;
            }
            .pos-10 {
              grid-area: 4 / 9;
            }
            .pos-11 {
              grid-area: 3 / 8;
            }
            .pos-12 {
              grid-area: 2 / 2;
            }
            .pos-13 {
              grid-area: 3 / 1;
            }
            .pos-13 {
              grid-area: 3 / 1;
            }
            .pos-14 {
              grid-area: 3 / 4;
            }
            .pos-15 {
              grid-area: 4 / 3;
            }
            .pos-16 {
              grid-area: 4 / 7;
            }
          }
        }
        .gallery__item {
          background-position: 50% 50%;
          background-size: cover;
          flex: none;
          border-radius: 6px;
          position: relative;
          filter: brightness(1);
        }
        .caption {
          z-index: 101;
          color: var(--color-caption);
          font-weight: 400;
          position: absolute;
          width: 100vw;
          height: 100vh;
          top: 50%;
          left: 50%;
          margin: 100vh 0 0 -50vw;
          display: grid;
          place-items: center;
          font-size: clamp(2rem, 8vw, 6rem);
        }
      }
      &.gallery--switch{
        &.gallery--grid10 {
          grid-template-columns: 1fr 300px 1fr;
          grid-template-rows: 1fr 60vh 1fr;
          grid-gap: 0;
          .gallery__item {
            grid-area: 2 / 2 / 3 / 3;
          }
          .caption {
            opacity: 1;
            margin-top: -50vh;
          }
        }
      }
    }
  }
}

.ready-section{
  //background-image: linear-gradient(135deg, #492cdd 0, #ad38e2 100%);
  //background-color: $yellow;
  padding: 200px 0;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 30px;
  display: flex;
  @media (max-width: $small-phone) {
    padding: 100px 25px;
  }
  .content{
    text-align: center;
    h4 {
      color: $black;
      font-size: 32px;
      font-weight: 400;
    }
    p {
      color: $black;
      font-size: 24px;
      font-weight: 300;
    }
  }
}

.contactUs {
  padding: 100px 0 50px;
  @media (max-width: $large-tablet) {
    padding: 50px 0;
  }
  background-image: linear-gradient(135deg, #492cdd 0, #ad38e2 100%);
  .content-wrapper {
    width: 60%;
    margin: 0 auto;
    @media (max-width: $large-tablet) {
      padding: 0 25px;
      width: 100%;
    }
    .image-wrapper {
      position: relative;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      @media (max-width: $large-tablet) {
        flex-direction: column;
      }
      .image-wrapper-outer{
        width: 80%;
        height: 520px;
        position: relative;
        background-color: $black;
        @media (max-width: $large-tablet) {
          width: 100%;
        }
        .image {
          width: 100%;
          height: 100%;
          background-size: cover !important;
          background-repeat: no-repeat !important;
          background-position: center center !important;
          position: relative;
          background-attachment: fixed !important;
          &:before {
            content: "";
            position: absolute;
            left: 0;
            bottom: 0;
            width: 100%;
            height: 85%;
            background: linear-gradient(0deg, $black 0%, rgba(255,255,255,0) 100%);
          }
        }
        p {
          width: 100%;
          color: $white;
          z-index: 2;
          text-align: center;
          padding: 0 150px 0 100px;
          font-size: 41px;
          font-weight: 300;
          letter-spacing: 0;
          line-height: 48px;
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          @media (max-width: $large-tablet) {
            font-size: 34px;
            padding: 0;
          }
        }
      }
      .mail-wrapper {
        width: 50%;
        padding: 50px;
        background-color: $white;
        margin-left: -80px;
        margin-top: 80px;
        z-index: 2;
        position: relative;
        box-shadow: 2px 2px 10px 10px #0000001f;
        @media (max-width: $large-tablet) {
          width: 100%;
          padding: 25px;
          margin-left: 0px;
          margin-top: 0px;
        }
        h4 {
          color: $black;
          font-size: 26px;
          font-weight: 300;
          letter-spacing: 0;
          line-height: 34px;
          margin-bottom: 42px;
        }
        .input-wrapper {
          margin-bottom: 2rem;
          .input-field{
            margin-bottom: 1.75rem;
            position: relative;
            .cust-input{
              &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
                color: $black-30;
                opacity: 1; /* Firefox */
              }

              &:-ms-input-placeholder { /* Internet Explorer 10-11 */
                color: $black-30;
              }

              &::-ms-input-placeholder { /* Microsoft Edge */
                color: $black-30;
              }
            }
            .checkbox-wrapper {
              display: flex;
              justify-content: flex-start;
              align-items: center;
              margin-top: 10px;
              gap: 10px;
              p {
                color:$black-80;
                font-size: 13px;
                font-weight: 300;
                letter-spacing: 0;
                line-height: 18px;
                padding-bottom: 0;
                a {
                  color:$black-80;
                  text-decoration: underline;
                }
              }
            }
          }
        }
        .button-wrapper {
          display: flex;
          justify-content: flex-end;
          .btn-custom{
            letter-spacing: 0;
            text-align: center;
            width: 130px;
            height: 50px;
            padding: 0;
            font-size: 16px;
            font-weight: 500;
            line-height: 16px;
          }
        }
      }
    }
  }
}

.footer-section{
  .dark-bg{
    background-color: $dark-grey;
  }
  .light-bg{
    background-color: $white;
  }
  .top-content, .bottom-content {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    position: relative;
    @media (max-width: $small-laptop) {
      flex-direction: column;
    }
    .left-wrapper {
      width: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      padding: 25px;
      @media (max-width: $small-laptop) {
        width: 100%;
        justify-content: flex-start;
        align-items: flex-start;
      }
    }
    .right-wrapper {
      width: 50%;
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;
      flex-direction: column;
      padding: 25px;
      @media (max-width: $small-laptop) {
        width: 100%;
        justify-content: center;
        align-items: center;
        padding: 50px 25px 15px;
      }
      .content-wrapper{
        display: block;
      }
    }
    .content-wrapper {
      width: 100%;
      //height: 500px;
      padding: 25px 0;
      display: flex;
      flex-direction: column;
      gap: 60px;
      @media (max-width: $large-phone) {
        height: auto;
        flex-direction: column;
        gap: 40px;
        min-height: 300px;
        padding: 0;
      }
      @media (max-width: $small-phone) {
        min-height: auto;
      }
      @media (max-width: $iphone-pro-max) {
        min-height: auto;
      }
      h4{
        font-size: 24px;
        font-weight: 400;
        margin-bottom: 10px;
      }
      .instaCarousel {
        margin: 0 auto;
        max-width: 1200px;
        width: 100%;
        .owl-stage-outer{
          .owl-stage {
            display: flex;
            align-items: center;
            padding: 1rem;
            height: 455px;
            @media (max-width: $large-tablet) {
              height: 605px;
            }
            .owl-item{
              .item {
                transition: 0.4s;
                background-color: #fff;
                border-radius: 6px;
                box-shadow: 1px 1px 12px 5px rgb(0 0 0 / 10%);
                //height: 300px;
                @media (max-width: $large-tablet) {
                  width:80%;
                  margin: 0 auto;
                }
                @media (max-width: $small-phone) {
                  width:90%;
                  margin: 0 auto;
                }
                .card-wrapper {
                  display: flex;
                  flex-direction: column;
                  .card-top-wrapper {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    padding: 10px 16px;
                    height: 50px;
                    .card-left-wrapper {
                      display: flex;
                      align-items: center;
                      gap: 6px;
                      .userInfo-wrapper {
                        display: flex;
                        align-items: center;
                        gap: 7px;
                        .user-avatar {
                          display: flex;
                          align-items: center;
                          justify-content: center;
                          width: 32px;
                          height: 32px;
                          border-radius: 50%;
                          color: #fff;
                          background: radial-gradient(circle at 30% 107%, #fdf497 0% 5%, #fd5949 45%, #d6249f 60%, #285aeb 90%);
                        }
                        .username-wrapper {
                          display: flex;
                          align-items: center;
                          gap: 6px;
                          p {
                            font-size: 12px;
                            font-weight: 500;

                          }
                          i{
                            color: #0797ed;
                          }
                        }
                      }
                    }
                    .card-right-wrapper{
                      i{
                        color: #444;
                      }
                    }
                  }
                  .image-wrapper {
                    width: calc(100% - 32px);
                    margin: 0 auto;
                    height: 220px;
                    background-color: cadetblue;
                    background-size: cover !important;
                    background-repeat: no-repeat !important;
                    background-position: center center !important;
                    @media (max-width: $large-tablet) {
                      height: 380px;
                    }
                  }
                }
                .card-bottom-wrapper {
                  display: flex;
                  align-items: start;
                  justify-content: space-between;
                  padding: 10px 16px;
                  height: 80px;
                  .card-left-wrapper {
                    display: flex;
                    flex-direction: column;
                    gap: 6px;
                    .reaction-wrapper {
                      display: flex;
                      gap: 8px;
                      font-size: 22px;
                       i{
                         color: #444;
                         &.fa-heart {
                           color: #ff121f;
                         }
                       }
                    }
                    .comments-wrapper {
                      p {
                        font-size: 10px;
                        text-align: left;
                        span {
                          font-weight: 500;
                        }
                      }
                    }
                  }
                  .card-right-wrapper{
                    font-size: 20px;
                    i{
                      color: #444;
                    }
                  }
                }
              }
              &.active {
                .item {
                  //height: 300px;
                  //transition: 0.2s;
                  //.image-wrapper {
                  //  height: 170px;
                  //  transition: 0.2s;
                  //}
                }
                &.center {
                  position: relative;
                  z-index: 2;
                  .item {
                    //height: 300px;
                    transition: 0.4s;
                    transform: scale(1.2);
                    @media (max-width: $large-tablet) {
                      transform: none;
                    }
                    //.image-wrapper {
                    //  height: 220px;
                    //  transition: 0.2s;
                    //}
                  }
                }
              }
            }
          }
        }

      }
    }
    .site-links {
      display: flex;
      gap: 25px;
      @media (max-width: $large-tablet) {
        gap: 10px
      }
      @media (max-width: $small-tablet) {
        gap: 30px
      }
      @media (max-width: $small-phone) {
        justify-content: space-evenly;
        align-items: center;
        gap: 5px;
        width: 100%;
      }
      li {
        span {
          color: $white;
          font-size: 13px;
          letter-spacing: 0;
          line-height: 23px;
          @media (max-width: $small-laptop) {
            color: $black;
          }
        }

        a {
          color: $white;
          font-size: 13px;
          font-weight: 300;
          letter-spacing: 0;
          line-height: 23px;
          transition: 0.2s ease-in;
          @media (max-width: $small-laptop) {
            color: $black;
          }
          &:hover{
            color: $white-50;
            @media (max-width: $small-laptop) {
              color: $black-50;
            }
          }
        }
      }
    }
    .social-links {
      justify-content: flex-end;
      align-items: center;
      gap: 20px;
      display: none;
      @media (max-width: $small-laptop) {
        display: flex;
      }
      li {
        a {
          color: $black;
          font-size: 20px;
          width: 20px;
          height: 20px;
          display: flex;
          justify-content: center;
          align-items: center;
          transition: 0.2s ease-in;
          &:hover{
            color: $bule;
          }
        }
      }
    }
    &.contact-footer{
      .dark-bg, .light-bg{
        background-color: $yellow;
      }
      .site-links, .social-links {
        li {
          span {
            color: $black;
          }
          a {
            color: $black;
            &:hover{
              color: $black-50;
            }
          }
        }
      }
    }
    .menu {
      position: absolute;
      right: 80px;
      bottom: 80px;
      z-index: 2;
      @media (max-width: $small-laptop) {
       display: none;
      }
      a {
        position: absolute;
        background-color: black;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 60px;
        height: 60px;
        border: 3px solid white;
        border-radius: 50%;
        transition: all 0.3s ease-in-out;
        color: #fff;
        font-size: 30px;
        &.hidden {
          visibility: hidden;
        }
        &:last-child {
          padding: 13px 15px
        }
        &:nth-child(1) {
          background: #d6249f;
          background: radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%,#d6249f 60%,#285AEB 90%);
          padding: 15px 15px;
          &.show {
            transform: rotate(0deg) translate(-100px) rotate(0deg)
          }
        }
        &:nth-child(2) {
          background-color: #0076b3 ;
          &.show {
            transform: rotate(43deg) translate(-100px) rotate(-43deg)
          }
        }
        &:nth-child(3) {
          background-color: #1877F2;
          &.show {
            transform: rotate(90deg) translate(-100px) rotate(-90deg)
          }
        }
      }
    }
  }
  .left-wrapper {
    &.dark-bg{
      .content-wrapper{
        padding: 145px 0;
        align-items: center;
        justify-content: center;
      }
    }
  }
  .bottom-content{
    @media (max-width: $small-laptop) {
      //flex-direction: column-reverse;
      flex-direction: row;
      position: relative;
      &:before{
        content: "";
        position: absolute;
        width: calc(100% - 30px);
        height: 1px;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        margin: 0 auto;
        background-color: $black-10;
      }
    }
    .left-wrapper {
      @media (max-width: $small-laptop) {
        width: 100%;
        justify-content: center;
        //align-items: center;
        align-items: flex-start;
      }
      &.dark-bg {
        @media (max-width: $small-laptop) {
          background-color: $white;
          padding: 15px 15px;
          //position: relative;
          //&:before{
          //  content: "";
          //  position: absolute;
          //  top: 0;
          //  width: calc(100% - 60px);
          //  height: 1px;
          //  background-color: $black-10;
          //}
        }
      }
    }
    .right-wrapper{
      @media (max-width: $small-laptop) {
        justify-content: center;
        //align-items: center;
        align-items: flex-end;
        padding: 10px 15px;
      }
    }
  }
}

.invalid-feedback,
.empty-feedback {
  display: none;
  color: #dc3545;
  position: absolute;
  bottom: -23px;
  left: 5px;
  font-size: 13px;
  font-weight: 300;
}

.was-validated :placeholder-shown:invalid ~ .empty-feedback {
  display: block;
}

.was-validated :not(:placeholder-shown):invalid ~ .invalid-feedback {
  display: block;
}

.is-invalid,
.was-validated :invalid {
  border-color: #dc3545;
}
.text-gray-400 {
  --tw-text-opacity: 1;
  color: rgba(156, 163, 175);
}
.text-green-500 {
  color: rgba(16, 185, 129);
}
.text-red-400 {
  color: #dc3545;
}
.text-base {
  font-size: 1rem;
  line-height: 1.5rem;
  margin-top: 1rem;
  text-align: center;
}
